@import url(https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300;400;500;600;700;800;900&family=Roboto:ital,wght@0,100;0,400;0,700;1,100;1,400;1,500&display=swap);
body {
  margin: 0;
  font-family: 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,h2,h3,h4,h5 {
  font-family: 'Heebo';
}

.App {
  background-color: #F4F4FC;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.flex {
  display: -webkit-flex;
  display: flex;
}

.stretch-fix {
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

.body-icon {
  color: #478EE0;
}

.body-title {
  color: #1C1C1E;
  font-size: 2rem;
  margin: 1.5rem 0 0rem;
  line-height: 2.5rem;
}

.body-text {
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  color: #1C1C1E;
  font-size: 1.15rem;
  line-height: 1.6rem;
}

@media (max-width: 450px) {
  .body-title {
    font-size: 1.75rem;
  }
  
  .body-text {
    font-size: 1.1rem;
    line-height: 1.5rem;
  }
}

@media (min-width: 1380px) {
  .body-title {
    font-size: 3rem;
    line-height: 4rem;
  }
  .body-text {
    font-size: 1.5rem;
    line-height: 2.2rem;
  }
}

.logo-size {
    width: 13rem;
}

@media (max-width: 1630px) {
    .logo-size {
        width: 10rem;
    }
}
@media (max-width: 1130px) {
    .logo-size {
        width: 7.5rem;
    }
}
@media (max-width: 535px) {
    .logo-size {
        width: 6rem;
    }
}
.TypingText {
    color: #1C1C1E;
    font-size: 5.5rem;
    font-weight: 800;
    margin: 1rem 0;
}

.section-flex {
    display: -webkit-flex;
    display: flex;
}

.column {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
}

.subTitle {
    color: #1C1C1E;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 2.5rem;
    line-height: 3rem;
}

.main-image {
    width: 70%;
    -webkit-transform: rotate(15deg);
            transform: rotate(15deg);
}

.center{
    -webkit-justify-content: center;
            justify-content: center;
}

.app-button {
    width: 210px;
}

@media (max-width: 1630px) {
    .TypingText {
        font-size: 4.5rem;
    }
    .subTitle {
        font-size: 1.5rem;
        line-height: 2rem;
    }
    .app-button {
        width: 175px;
    }
}

@media (max-width: 1380px) {
    .TypingText {
        font-size: 3.5rem;
        margin: .75rem 0;
    }
    .subTitle {
        font-size: 1.25rem;
    }
}

@media (max-width: 1130px) {
    .TypingText {
        font-size: 2.75rem;
    }
}

@media (max-width: 960px) {
    .TypingText {
        font-size: 3.25rem;
        text-align: center;
    }
    .subTitle {
        font-size: 1.3rem;
        text-align: center;
    }
    .section-flex{
        -webkit-flex-direction: column;
                flex-direction: column;
    }
    .app-button-container {
        -webkit-justify-content: center;
                justify-content: center;
        margin: 2rem;
    }
    .main-image {
        width: 50%;
    }
    .TypingText-container {
        -webkit-justify-content: center;
                justify-content: center;
    }
}

@media (max-width: 535px) {
    .TypingText {
        font-size: 2.5rem;
    }
    .main-image {
        width: 70%;
    }
    .app-button {
        width: 150px;
    }
}

@media (max-width: 380px) {
    .TypingText {
        font-size: 2rem;
        margin: .4rem 0;
    }
    .subTitle {
        font-size: 1.15rem;
        line-height: 1.5rem;
    }
    .app-button {
        width: 140px;
    }
    .app-button-container {
        margin: 1.5rem;
    }
    .info-container {
        margin-top: 1.5rem;
    }
}

@media (max-width: 315px) {
    .TypingText {
        font-size: 1.7rem;
    }
    .app-button {
        width: 130px;
    }
}

@media (max-width: 280px) {
    .TypingText {
        font-size: 1.3rem;
        margin: .2rem 0;
    }
    .app-button {
        width: 120px;
    }
}


.column {
    -webkit-flex-direction: column;
            flex-direction: column;
}

.center {
    -webkit-align-items: center;
            align-items: center;
}

.spaced {
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
}

.vertical-position {
    max-width: 28rem;
    padding: 0 16px;
}

@media (max-width: 960px) {
    .flip {
        -webkit-flex-direction: column;
                flex-direction: column;
    }
    .top-card {
        padding: 0 1rem;
    }
    .middle-card {
        padding: 3rem 0;
    }
    .bottom-card {
        padding: 0 1rem;
    }
    .vertical-position {
        padding: 0;
    }
}
.pi-container {
    -webkit-flex-direction: column;
            flex-direction: column;
}

.arrow-icon {
    color: #478EE0;
}
.lhc-body-container {
    width: 70%;
}

.lhc-image-container {
    width: 30%;
    min-width: 10rem;
    height: auto;
}

.lhc-container-left, .lhc-container-right {
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-align-items: center;
            align-items: center;
    padding: 0 64px;
}

.lhc-left{
    margin-right: 3rem;
}

.lhc-right{
    margin-left: 3rem;
}

.lhc-image {
    width: 50%;
}

.lhc-text {
    text-align: left;
}

@media (max-width: 750px) {
    .horizontal-middle {
        margin: 0 0;
    }
    .lhc-container-left {
        -webkit-flex-direction: column;
                flex-direction: column;
    }
    .lhc-container-right {
        -webkit-flex-direction: column-reverse;
                flex-direction: column-reverse;
    }
    .lhc-left{
        margin-right: 0;
    }
    .lhc-right{
        margin-left: 0;
    }
    .lhc-body-container {
        width: 90%;
    }
}

@media (max-width: 610px) {
    .lhc-container-left {
        padding: 0;
    }
    .lhc-container-right {
        padding: 0;
    }
}


.flip-places {
    -webkit-flex-direction: column;
            flex-direction: column;
}

.horizontal-middle {
    margin: 3.5rem 0;
}

@media (max-width: 960px) {
    .horizontal-middle {
        margin: 2rem 0;
    }
}

@media (max-width: 690px) {
    .horizontal-middle {
        margin: 0 0;
    }
}


.center {
    -webkit-align-items: center;
            align-items: center;
}

.horizontal-icon {
    color: #478EE0;
}

.horizontal-title {
    margin: 0 0 1rem
}

.horizontal-body {
    text-align: left;
}

.horizontal-position {
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: start;
            justify-content: start;
    padding: 0 5rem;
    max-width: 80rem;
}

.horizontal-icon {
    width: 10rem;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 0 6rem
}

@media (max-width: 1100px) {
    .horizontal-position {
        padding: 0 3rem;
    }
}

@media (max-width: 690px) {
    .horizontal-position {
        -webkit-flex-direction: column;
                flex-direction: column;
        padding: 0;
    }
    .horizontal-title {
        text-align: center;
        margin: 1.5rem 0 0rem;
    }

    .horizontal-body {
        text-align: center;
    }
}
.cta-text {
    text-align: center;
}

.cta-container{
    max-width: 50rem;
}
.footer-position {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
}

.footer-icons {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.footer-icon-container {
    margin: 0 1rem;
}

.footer-icon {
    color: #478EE0;
}

.footer-title {
    padding-bottom: 2rem;
}

.footer-copyright {
    color: #1C1C1E;
    font-weight: 300;
}

.footer-contacttext {
    text-align: center;
    padding: 2rem 0;
}

@media (max-width: 450px) {
    .footer-title {
      font-size: 1.75rem;
    }
}

@media (max-width: 295px) {
    .footer-icon-container {
        margin: 0 .5rem;
    }
}
