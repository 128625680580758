.App {
  background-color: #F4F4FC;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.flex {
  display: flex;
}

.stretch-fix {
  align-items: flex-start;
}

.body-icon {
  color: #478EE0;
}

.body-title {
  color: #1C1C1E;
  font-size: 2rem;
  margin: 1.5rem 0 0rem;
  line-height: 2.5rem;
}

.body-text {
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  color: #1C1C1E;
  font-size: 1.15rem;
  line-height: 1.6rem;
}

@media (max-width: 450px) {
  .body-title {
    font-size: 1.75rem;
  }
  
  .body-text {
    font-size: 1.1rem;
    line-height: 1.5rem;
  }
}

@media (min-width: 1380px) {
  .body-title {
    font-size: 3rem;
    line-height: 4rem;
  }
  .body-text {
    font-size: 1.5rem;
    line-height: 2.2rem;
  }
}
